import { Component, Input, OnInit } from '@angular/core';
import { IEventNote } from 'src/app/core/interfaces/event-note';
import { EventNotesService } from 'src/app/core/services/api/event-notes.service';
import { ConfirmationComponent, ConfirmationData } from '../dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit{
  @Input() eventId!:number
  private _isEdit = false
  private _editIndex = -1
  private _comments:IEventNote[] =[]
  comment = ""
  get comments(){
    return this._comments
  }
  get editIndex(){
    return this._editIndex
  }
  get isEdit(){
    return this._isEdit
  }
  constructor(private eventNotesService: EventNotesService, private dialog:MatDialog){}
  ngOnInit(): void {
    this.eventNotesService.get(this.eventId).subscribe({
      next:(comments)=>{
        this._comments = comments
      }
    })
  }
  send(){
    if(!this.isEdit){
      this.createNote()
    } else{
      this.editNote()
    }
  }
  edit(index:number){
    this._isEdit = true
    this._editIndex = index
    this.comment = this._comments[index].note
  }
  confirmDelete(index:number){
    const data = new ConfirmationData()
        data.description = '¿Está seguro que deseas eliminar la nota?'
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: data,
        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.delete(index)
            }
        })
  }
  delete(index:number){
    const id = this._comments[index].id
    this.eventNotesService.delete(id!!).subscribe(()=>{
      this._comments.splice(index, 1)
    })
  }
  cancel(){
    this._isEdit = false
    this._editIndex = -1
    this.comment = ""
  }
  private createNote(){
    const note:IEventNote = {
      date: new Date,
      note: this.comment,
      eventId: this.eventId
    }
    this.eventNotesService.create(note).subscribe((response)=>{
      this._comments.push(response)
      this.comment = ""
    })
  }
  private editNote(){
    const note:IEventNote = {
      id: this.comments[this.editIndex].id,
      date: new Date,
      note: this.comment,
      eventId: this.eventId
    }
    this.eventNotesService.update(note).subscribe((response)=>{
      this._comments[this.editIndex] = note
      this.comment = ""
      this._editIndex = -1
    })
  }
}
