import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CurrencyPipe, DatePipe } from '@angular/common'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoginComponent } from './ui/pages/login/login.component'
import { DashboardComponent } from './ui/pages/dashboard/dashboard.component'
import { NavbarComponent } from './ui/components/navbar/navbar.component'
import { SideNavTogglerComponent } from './ui/components/side-nav-toggler/side-nav-toggler.component'
import { SideNavComponent } from './ui/components/side-nav/side-nav.component'
import { PageHeaderComponent } from './ui/components/page-header/page-header.component'
import { AuthGuardService } from './core/services/util/auth-guard.service'
import { HomePageComponent } from './ui/pages/home-page/home-page.component'
import { IngresosPageComponent } from './ui/pages/ingresos-page/ingresos-page.component'
import { EgresosComponent } from './ui/pages/egresos/egresos.component'
import { HttpClientModule } from '@angular/common/http'
import { AddDialogComponent } from './ui/components/dialogs/add-dialog/add-dialog.component'
import { authInterceptorProviders } from './core/services/api/helpers/http-interceptor'
import { CategoriesComponent } from './ui/pages/categories/categories.component'
import { SubcategoriesComponent } from './ui/pages/subcategories/subcategories.component'
import { AddSubcategoryComponent } from './ui/components/dialogs/add-subcategory/add-subcategory.component'
import { CookieService } from 'ngx-cookie-service'

/*Material imports*/
import { MatIconModule } from '@angular/material/icon'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatMenuModule } from '@angular/material/menu'
import { AddRecordComponent } from './ui/components/dialogs/add-record/add-record.component'
import { MatSortModule } from '@angular/material/sort'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatCardModule } from '@angular/material/card'
import { MatInputModule } from '@angular/material/input'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatExpansionModule } from '@angular/material/expansion'
import { DailyCutComponent } from './ui/pages/reports/daily-cut/daily-cut.component'
import { MensualBalanceComponent } from './ui/pages/reports/mensual-balance/mensual-balance.component'
import { CashFlowComponent } from './ui/pages/reports/cash-flow/cash-flow.component'
import { MomentDateModule } from '@angular/material-moment-adapter'
import { CashFlowSimpleComponent } from './ui/pages/reports/cash-flow-simple/cash-flow-simple.component'
import { PettyComponent } from './ui/pages/petty/petty.component'
import { AddPettyComponent } from './ui/components/dialogs/add-petty/add-petty.component'
import { registerLocaleData } from '@angular/common'

import localeEs from '@angular/common/locales/es'
import { MonthPickerComponent } from './ui/components/month-picker/month-picker.component'
import { TicketComponent } from './ui/components/util/ticket/ticket.component'
import { LocationsComponent } from './ui/pages/locations/locations.component'
import { AddLocationComponent } from './ui/components/dialogs/add-location/add-location.component'
import { DailyCutRestrictedComponent } from './ui/pages/reports/daily-cut-restricted/daily-cut-restricted.component'
import { ComparisonComponent } from './ui/pages/reports/comparison/comparison.component'
import { MonthRangePickerComponent } from './ui/components/month-range-picker/month-range-picker.component'
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/moment'
import * as moment from 'moment'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonModule } from '@angular/material/button'
import { MatSelectModule } from '@angular/material/select'
import { MatDialogModule } from '@angular/material/dialog'
import { CalendarComponent } from './ui/pages/calendar/calendar.component'
import { EventDetailComponent } from './ui/components/dialogs/event-detail/event-detail.component'
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker'
import { CalendarGuardService } from './core/services/util/calendar-guard.service'
import { EventsComponent } from './ui/components/events/events.component'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatStepperModule } from '@angular/material/stepper'

import { ConfirmationComponent } from './ui/components/dialogs/confirmation/confirmation.component'
import { EventFilterComponent } from './ui/components/dialogs/event-filter/event-filter.component'
import { CategoriesService } from './core/services/api/categories.service'
import { SubcategoriesService } from './core/services/api/subcategories.service'
import { AddIncomeEventWizardComponent } from './ui/components/dialogs/add-income-event-wizard/add-income-event-wizard.component';
import { EventEditAddDialogComponent } from './ui/components/dialogs/event-edit-add-dialog/event-edit-add-dialog.component'
import { ConfigurationService } from './core/services/api/configuration.service';
import { CommentsComponent } from './ui/components/comments/comments.component'

export function momentAdapterFactory() {
    return adapterFactory(moment)
}
export function initializeConfig(configService: ConfigurationService){
    return ()=> configService.retrieve()
}

export function initializeData(
    categoriesService: CategoriesService,
    subcategoriesService: SubcategoriesService
) {
    return () => {
        categoriesService.updateData()
        subcategoriesService.updateData()
    }
}
//registerLocaleData(localeEs, 'es')
registerLocaleData(localeEs)

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        NavbarComponent,
        SideNavTogglerComponent,
        SideNavComponent,
        PageHeaderComponent,
        HomePageComponent,
        IngresosPageComponent,
        EgresosComponent,
        AddDialogComponent,
        CategoriesComponent,
        SubcategoriesComponent,
        AddSubcategoryComponent,
        AddRecordComponent,
        ConfirmationComponent,
        DailyCutComponent,
        CashFlowComponent,
        MensualBalanceComponent,
        CashFlowSimpleComponent,
        PettyComponent,
        AddPettyComponent,
        MonthPickerComponent,
        TicketComponent,
        LocationsComponent,
        AddLocationComponent,
        DailyCutRestrictedComponent,
        ComparisonComponent,
        MonthRangePickerComponent,
        CalendarComponent,
        EventDetailComponent,
        EventsComponent,
        EventFilterComponent,
        AddIncomeEventWizardComponent,
        EventEditAddDialogComponent,
        CommentsComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatCheckboxModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatTabsModule,
        MatSnackBarModule,
        MatTooltipModule,
        NgxMatTimepickerModule,
        MatStepperModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: momentAdapterFactory,
        }),
    ],
    providers: [
        AuthGuardService,
        CalendarGuardService,
        CookieService,
        MomentDateModule,
        authInterceptorProviders,
        DatePipe,
        CurrencyPipe,
        { provide: MAT_DATE_LOCALE, useValue: 'es-GB' },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [CategoriesService, SubcategoriesService],
            useFactory: initializeData,
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [ConfigurationService],
            useFactory: initializeConfig,
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
