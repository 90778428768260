<div class="container-fluid">
    <div class="detail-title">
        <h3 mat-dialog-title><mat-icon>event</mat-icon>{{ data.description }}</h3>
        
        <button (click)="openRecord()" mat-button>Abrir ingreso</button>
        <button (click)="confirmDelete()" mat-button>Eliminar Evento</button>
    </div>
    

    <div class="mat-dialog-content">
        <!--Contains mat-hint for characters count and has maxLengt set-->
        <div class="div">
            <p>
                {{ data.eventDateStart | date: 'dd/MM/yyyy' }}
                {{ formGroup.get('StartTime')?.value }} -
                {{ data.eventDateEnd | date: 'dd/MM/yyyy' }}
                {{ formGroup.get('End Time')?.value }}
            </p>
        </div>
        <div class="row content">
            <div class="col-md-6 event-details">
                <h4>Detalles del evento</h4>
                <div class="events">
                    <form autocomplete="off" [formGroup]="formGroup">
                        <div class="full_width">
                            <mat-form-field>
                                <mat-label>Titulo del Evento</mat-label>
                                <input matInput formControlName="Title" />
                            </mat-form-field>
                        </div>
        
                        <div class="field-container">
                            <mat-form-field color="accent">
                                <mat-label>Fecha de Inicio</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="picker1"
                                    formControlName="StartDate"
                                    [min]="today"
                                />
                                <mat-hint>DD/MM/YYYY</mat-hint>
                                <mat-datepicker-toggle
                                    matIconSuffix
                                    [for]="picker1"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field color="accent">
                                <mat-label>Hora de Inicio</mat-label>
                                <input
                                    matInput
                                    [ngxMatTimepicker]="pickerA"
                                    readonly
                                    formControlName="StartTime"
                                />
                                <mat-icon matSuffix (click)="pickerA.open()">
                                    watch_later
                                </mat-icon>
                            </mat-form-field>
                            <ngx-mat-timepicker #pickerA></ngx-mat-timepicker>
                        </div>
                        <div class="field-container">
                            <mat-form-field color="accent">
                                <mat-label>Fecha Final</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="picker2"
                                    formControlName="EndDate"
                                    [min]="today"
                                />
                                <mat-hint>DD/MM/YYYY</mat-hint>
                                <mat-datepicker-toggle
                                    matIconSuffix
                                    [for]="picker2"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field color="accent">
                                <mat-label>Hora Final</mat-label>
                                <input
                                    matInput
                                    [ngxMatTimepicker]="pickerB"
                                    readonly
                                    formControlName="EndTime"
                                />
                                <mat-icon matSuffix (click)="pickerB.open()">
                                    watch_later
                                </mat-icon>
                            </mat-form-field>
                            <ngx-mat-timepicker #pickerB></ngx-mat-timepicker>
                        </div>
                    </form>
                    <div class="mat-dialog-actions">
                        <button (click)="save()" mat-button>Editar</button>
                        <button mat-dialog-close mat-button>Cancelar</button>
                    </div>
                </div>
        
                
            </div>
            <div class="col-md-6">
                <h4>Comentarios</h4>
                <app-comments *ngIf="data.id !== undefined" [eventId]="data.id"></app-comments>
            </div>
        </div>
        
    </div>
</div>
