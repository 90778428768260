import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { COOKIE_ROLE, JWT_TOKEN } from 'src/app/core/const/constants'
import { AuthService } from 'src/app/core/services/api/auth-service.service'
import { SessionManagementService } from 'src/app/core/services/util/session-management.service'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { LoadingService } from 'src/app/core/services/util/loading.service'
import { ConfigurationService } from 'src/app/core/services/api/configuration.service'
import { firstValueFrom } from 'rxjs'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
    loginForm!: UntypedFormGroup
    error = false
    constructor(
        private authService: AuthService,
        private loadingService: LoadingService,
        private configurationService: ConfigurationService,
        private router: Router,
        private cookieService: CookieService
    ) {}

    ngOnInit(): void {
        this.loginForm = new UntypedFormGroup({
            username: new UntypedFormControl(null),
            password: new UntypedFormControl(null),
        })
    }

    async login() {
        this.loadingService.show()
        this.authService.login(this.loginForm.value).subscribe(
            async (response: any) => {
                this.error = false
                const decoded = jwt_decode<any>(response.token || '') || null
                const role =
                    decoded[
                        'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
                    ]
                this.cookieService.set(COOKIE_ROLE, role)
                this.cookieService.set(JWT_TOKEN, response.token)
                this.loadingService.hide()
                await this.configurationService.retrieve()
                if (role == 'Administrator') {
                    this.router.navigateByUrl('admin')
                } else {
                    this.router.navigateByUrl('')
                }
            },
            (error: any) => {
                this.error = true
                console.log(error)
                this.loadingService.hide()
            }
        )
    }
}
