<div class="container comments-component">
    <div class="comments vh-80">
        <div class="no-comments" *ngIf="comments.length === 0 "><p>No hay comentarios</p></div>
        <div *ngFor="let comment of comments; let i = index" class="comment" [ngClass]="i === editIndex ? 'edit-comment' : ''">
            <p><span>{{comment.date | date: 'dd/MM/YYYY'}} -</span> {{comment.note}}</p>
            <div class="actions">
                <mat-icon (click)="edit(i)">edit</mat-icon>
                <mat-icon (click)="confirmDelete(i)">delete</mat-icon>
            </div>
        </div>
    </div>
    <div class="form vh-20">
        <mat-form-field class="example-full-width">
            <mat-label>Agregar comentarios</mat-label>
            <textarea matInput [(ngModel)]="comment"></textarea>
          </mat-form-field>
          <div class="buttons">
            <button mat-raised-button (click)="send()">Enviar</button>
            <button [disabled]="!isEdit" mat-raised-button (click)="cancel()">Cancelar</button>
          </div>
        
    </div>

</div>
