import { Injectable } from '@angular/core';
import { IEventNoteApi } from '../../interfaces/api/event-note-service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IEventNote } from '../../interfaces/event-note';
import { PROD_API_URL } from '../../const/constants';

const API_URL = '/api/eventnotes'
@Injectable({
  providedIn: 'root'
})
export class EventNotesService implements IEventNoteApi{
  

  constructor(private httpClient: HttpClient) {}
  create(eventNote: IEventNote): Observable<IEventNote> {
    return this.httpClient.post<IEventNote>(`${PROD_API_URL}${API_URL}`, eventNote)
  }
  update(eventNote: IEventNote): Observable<void> {
    return this.httpClient.put<void>(`${PROD_API_URL}${API_URL}/${eventNote.id}`, eventNote)
  }
  delete(eventNoteId: number): Observable<void> {
    return this.httpClient.delete<void>(`${PROD_API_URL}${API_URL}/${eventNoteId}`)
  }
  get(eventId: number): Observable<IEventNote[]> {
    return this.httpClient.get<IEventNote[]>(`${PROD_API_URL}${API_URL}/event/${eventId}`)
  }
}
